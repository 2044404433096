import React from 'react';

import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { Button, Form } from 'reactstrap';

import actions from '../../actions';
import { isUserEqual } from '../../util/comparison';
import {
  emailValidator,
  firstNameValidator,
  lastNameValidator,
  passwordValidator,
  phoneValidator,
  refereeYearsValidator,
  repeatPasswordValidator,
  usernameValidator,
} from '../../util/validators';
import ErrorMessage from './ErrorMessage';
import { ProfileInput } from './FormInput';

interface StateProps {
  user: Readonly<User> | undefined;
  error: Error | undefined;
}

interface DispatchProps {
  fetchCurrentUser: typeof actions.user.fetchCurrentUser;
  editCurrentUser: typeof actions.user.editCurrentUser;
  editPassword: typeof actions.user.editPassword;
}

interface Props extends StateProps, DispatchProps { }

export interface OwnState {
  username: string;
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  referee_years: string;
  oldPassword: string;
  newPassword: string;
  repeatNewPassword: string;
}

class CurrentUserDetail extends React.Component<Props, OwnState> {
  state: OwnState = {
    username: '',
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    referee_years: '',
    oldPassword: '',
    newPassword: '',
    repeatNewPassword: '',
  };

  constructor(props: Props) {
    super(props);

    const newUser = props.user;
    if (newUser !== undefined) {
      const { id, is_active, is_staff, referee_years, ...newUserState } = newUser;
      this.state = {
        ...newUserState,
        referee_years: referee_years.toString(),
        oldPassword: '',
        newPassword: '',
        repeatNewPassword: '',
      };
    }
  }

  componentDidMount() {
    this.props.fetchCurrentUser();
  }

  componentDidUpdate(prevProps: Props) {
    const oldUser = prevProps.user;
    const newUser = this.props.user;

    if (!isUserEqual(oldUser, newUser) && newUser !== undefined) {
      const { id, uuid, is_active, is_staff, referee_years, ...newUserState } = newUser;

      this.setState({
        ...newUserState,
        referee_years: referee_years.toString(),
      });
    }
  }

  updateUser(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    if (this.props.user === undefined) {
      console.error('User is undefined');
      return;
    }

    const { id } = this.props.user;
    const { username, first_name, last_name, email, phone, referee_years } = this.state;

    const allFieldsValid = usernameValidator(username) &&
      firstNameValidator(first_name) &&
      lastNameValidator(last_name) &&
      emailValidator(email) &&
      phoneValidator(phone) &&
      refereeYearsValidator(referee_years);

    if (!allFieldsValid) {
      // TODO: Create an error here and display?
      console.log('All fields not valid');
      return;
    }

    // TODO: Omit unchanged values using oldUser
    const newUser: UserPutBody = {
      username,
      first_name,
      last_name,
      email,
      phone,
      referee_years: parseInt(referee_years, 10),
    };

    this.props.editCurrentUser(id, newUser);
  }

  updatePassword(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    const { user } = this.props;
    if (user === undefined) {
      console.error('User is undefined');
      return;
    }

    const { oldPassword, newPassword, repeatNewPassword } = this.state;
    if (oldPassword === '') {
      console.error('Old password is empty');
      return;
    }
    if (!passwordValidator(newPassword) || !repeatPasswordValidator(newPassword, repeatNewPassword)) {
      console.error('New passwords are not valid');
      return;
    }

    // Action verifies old password, we have no way of determining whether it's valid in frontend
    this.props.editPassword(user, oldPassword, newPassword);
    this.setState({
      oldPassword: '',
      newPassword: '',
      repeatNewPassword: '',
    });
  }

  handleUserInput(e: React.FormEvent<HTMLInputElement>) {
    const name = e.currentTarget.name;
    const value = e.currentTarget.value;

    // Ugly type assertion but works due to component prop checks
    this.setState({ [name]: value } as unknown as Pick<OwnState, keyof OwnState>);
  }

  render(): JSX.Element {
    // TODO show loading
    const { error } = this.props;
    const {
      username,
      first_name,
      last_name,
      email,
      phone,
      referee_years,
      oldPassword,
      newPassword,
      repeatNewPassword,
    } = this.state;

    return (
      <div>
        <h2>Profiili</h2>
        <ErrorMessage error={error} />
        <Form onSubmit={(e) => this.updateUser(e)}>
          <ProfileInput
            name="username"
            label="Käyttäjätunnus 1"
            type="text"
            value={username}
            onChange={(e) => this.handleUserInput(e)}
            validator={usernameValidator}
            feedback="Käyttäjänimi voi sisältää numeroita, kirjaimia sekä merkkejä '@.-_'"
          />
          <ProfileInput
            name="first_name"
            label="Etunimi"
            type="text"
            value={first_name}
            onChange={(e) => this.handleUserInput(e)}
            validator={firstNameValidator}
            feedback="Teksti voi olla maksimissaan 30 merkkiä pitkä"
          />
          <ProfileInput
            name="last_name"
            label="Sukunimi"
            type="text"
            value={last_name}
            onChange={(e) => this.handleUserInput(e)}
            validator={lastNameValidator}
            feedback="Teksti voi olla maksimissaan 150 merkkiä pitkä"
          />
          <ProfileInput
            name="email"
            label="Sähköposti"
            type="email"
            value={email}
            onChange={(e) => this.handleUserInput(e)}
            validator={emailValidator}
            feedback="Sähköpostin pitää sisältää @-merkki, ja olla maksimissaan 254 merkkiä pitkä"
          />
          <ProfileInput
            name="phone"
            label="Puhelinnumero"
            type="tel"
            value={phone}
            onChange={(e) => this.handleUserInput(e)}
            validator={phoneValidator}
            feedback="Puhelinnumero voi sisältää numeroita, välilyöntejä tai merkkejä '+-'"
          />
          <ProfileInput
            name="referee_years"
            label="Aiemmat tuomarointivuodet"
            type="number"
            value={referee_years}
            onChange={(e) => this.handleUserInput(e)}
            validator={refereeYearsValidator}
            feedback="Kenttä ei voi olla negatiivinen"
          />
          <Button type="submit">Päivitä tiedot</Button>
        </Form>
        <hr />
        <h4>Salasanan vaihtaminen</h4>
        <Form onSubmit={(e) => this.updatePassword(e)}>
          <ProfileInput
            name="oldPassword"
            label="Vanha salasana"
            type="password"
            value={oldPassword}
            onChange={(e) => this.handleUserInput(e)}
            required
            validator={(value) => value !== ''}
            feedback="Kenttä ei voi olla tyhjä, jos haluat vaihtaa salasanan"
          />
          <ProfileInput
            name="newPassword"
            label="Uusi salasana"
            type="password"
            value={newPassword}
            onChange={(e) => this.handleUserInput(e)}
            required
            validator={passwordValidator}
            tip="Salasanan tulee olla vähintään 8 merkkiä pitkä, eikä saa koostua pelkästään numeroista"
          />
          <ProfileInput
            name="repeatNewPassword"
            label="Uusi salasana uudestaan"
            type="password"
            value={repeatNewPassword}
            onChange={(e) => this.handleUserInput(e)}
            required
            valid={repeatPasswordValidator(newPassword, repeatNewPassword)}
            feedback="Salasanojen tulee täsmätä"
          />
          <Button type="submit">Vaihda salasana</Button>
        </Form>
      </div>
    );
  }
}

const mapStateToProps: MapStateToProps<StateProps, {}, State> = (state: State) => ({
  user: state.currentUserDetail.user,
  error: state.currentUserDetail.error,
});

const mapDispatchToProps: MapDispatchToProps<DispatchProps, {}> = {
  fetchCurrentUser: actions.user.fetchCurrentUser,
  editCurrentUser: actions.user.editCurrentUser,
  editPassword: actions.user.editPassword,
};

export default connect(mapStateToProps, mapDispatchToProps)(CurrentUserDetail);
