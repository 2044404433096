import { Reducer } from 'redux';

import { UserAction } from '../types/actions';
import * as actions from '../types/actions';

const updateUser = (state: Readonly<UserState>, newUser: Readonly<User>): User[] => {
  const newUsers = state.users.slice();
  const index = newUsers.findIndex((user) => user.id === newUser.id);

  if (index === -1) {
    console.error(`Game with ID ${newUser.id} not found in state`);
    return newUsers;
  }

  newUsers[index] = newUser;

  return newUsers;
};

const initialState: UserState = {
  loading: false,
  users: [],
  error: undefined,
};

const reducer: Reducer<UserState, UserAction> = (state = initialState, action: UserAction): UserState => {
  switch (action.type) {
    case actions.FETCH_USERS: switch (action.status) {
      case 'PENDING': {
        return {
          ...state,
          loading: true,
        };
      }
      case 'SUCCESS': {
        return {
          ...state,
          loading: false,
          users: action.data,
        };
      }
      case 'ERROR': {
        return {
          ...state,
          loading: false,
          error: action.error,
        };
      }
    }
    case actions.TOGGLE_ADMIN: switch (action.status) {
      case 'PENDING': {
        return {
          ...state,
          loading: true,
        };
      }
      case 'SUCCESS': {
        const newUsers = updateUser(state, action.data);

        return {
          ...state,
          loading: false,
          users: newUsers,
        };
      }
      case 'ERROR': {
        return {
          ...state,
          loading: false,
          error: action.error,
        };
      }
    }
    case actions.RESET_USER_PASSWORD: switch (action.status) {
      case 'PENDING': {
        return {
          ...state,
          loading: true,
        };
      }
      case 'SUCCESS': {
        return {
          ...state,
          loading: false,
        };
      }
      case 'ERROR': {
        return {
          ...state,
          loading: false,
          error: action.error,
        };
      }
    }
    default:
      return state;
  }
};

export default reducer;
