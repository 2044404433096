import React from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Input } from 'reactstrap';
import actions from '../../actions';

interface Props {
  referee: Referee;
  currentUser: User | undefined;
  resetUserPassword: any; // typeof actions.user.resetUserPassword;
}

interface OwnState {
  isOpen: boolean;
}

export default class ResetPasswordModal extends React.Component<Props> {
  state: OwnState = {
    isOpen: false,
  };

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }

  show() {
    this.setState({
      isOpen: true,
    });
  }

  onCancel() {
    this.setState({
      isOpen: false,
    });
  }

  onConfirm() {
    const { referee, currentUser, resetUserPassword } = this.props;

    if (currentUser === undefined) {
      console.error('User is undefined, not allowed to reset password');
      return;
    }


    resetUserPassword(referee.user);

    this.setState({
      isOpen: false,
    });
  }


  render() {
    const { referee } = this.props;

    const user = referee.user;

    return (
      <Modal isOpen={this.state.isOpen} toggle={() => this.toggle()}>
        <ModalHeader toggle={() => this.toggle()}>Salasanan nollaaminen</ModalHeader>
        <ModalBody>
          {`Olet nollaamassa tuomarin ${user.first_name} ${user.last_name} (${user.username}) salasanan.`}
          <br />
          <br />
          {`Nollaaminen asettaa tuomarin salasanaksi "Salasana123"`}
          <br />
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => this.onCancel()}>Peruuta</Button>
          <Button color="danger" onClick={() => this.onConfirm()}>Nollaa</Button>
        </ModalFooter>
      </Modal>
    );
  }
}
