import { AxiosResponse } from 'axios';
import { Base64 } from 'js-base64';
import { Dispatch } from 'redux';

import * as actions from '../types/actions';
import waxios from './waxios';

const tulospalveluUrl = 'https://tulospalvelu.kyykka.fi';

type FetchRefereesAction = () => void;

export const fetchReferees: FetchRefereesAction = () => async (dispatch: Dispatch<actions.KnownAction>) => {
  dispatch({
    type: actions.FETCH_REFEREES,
    status: 'PENDING',
  });
  try {
    const result: AxiosResponse<Referee[]> = await waxios.get('/referee');
    console.log(result);

    dispatch({
      type: actions.FETCH_REFEREES,
      status: 'SUCCESS',
      data: result.data,
    });

    if (result.data.length === 0) {
      console.log('No referees');
      return;
    }

    // Now we can fetch referee enum field choices
    try {
      const knownId = result.data[0].id;

      dispatch({
        type: actions.FETCH_REFEREE_CHOICES,
        status: 'PENDING',
      });
      const choiceResult: AxiosResponse<RefereePutOptions> = await waxios.request({
        url: `/referee/${knownId}`,
        method: 'OPTIONS',
      });

      const choiceResultData = choiceResult.data;
      const refereeChoices: RefereeChoices = {
        status: choiceResultData.actions.PUT.status.choices,
        deposit: choiceResultData.actions.PUT.deposit.choices,
        reward_given: choiceResultData.actions.PUT.reward_given.choices,
      };

      dispatch({
        type: actions.FETCH_REFEREE_CHOICES,
        status: 'SUCCESS',
        data: refereeChoices,
      });

    } catch (error) {
      dispatch({
        type: actions.FETCH_REFEREE_CHOICES,
        status: 'ERROR',
        error,
      });
    }

  } catch (error) {
    dispatch({
      type: actions.FETCH_REFEREES,
      status: 'ERROR',
      error,
    });
  }
};

type UpdateRefereeAction = (referee: Referee, variableName: keyof Referee, value: string | number) => void;

export const updateReferee: UpdateRefereeAction = (referee, variableName, value) =>
  async (dispatch: Dispatch<actions.KnownAction>) => {
    dispatch({
      type: actions.UPDATE_REFEREE,
      status: 'PENDING',
    });

    try {
      const url = `/referee/${referee.id}`;
      await waxios.patch(url, {
        [variableName]: value,
      });

      const newReferee: Referee = {
        ...referee,
        [variableName]: value,
      };

      dispatch({
        type: actions.UPDATE_REFEREE,
        status: 'SUCCESS',
        data: newReferee,
      });

    } catch (error) {
      dispatch({
        type: actions.UPDATE_REFEREE,
        status: 'ERROR',
        error,
      });
    }
  };

type SendRefereeMessageAction = (refereeId: number | undefined, message: string, user: User) => void;

/**
 * Send a message a specific referee or all referees
 * @param refereeId Referee userId, use undefined to send the message to all referees
 * @param message
 */
export const sendRefereeMessage: SendRefereeMessageAction = (refereeId, message, user) =>
  async (dispatch: Dispatch<actions.SendRefereeMessage>) => {
    dispatch({
      type: actions.SEND_REFEREE_MESSAGE,
      status: 'PENDING',
    });

    try {
      // https://tulospalvelu.kyykka.fi/admin/addmessage.php?jid=1&message=asd
      const dto: { [k: string]: any } = {
        message,
        token: user.uuid,
      };
      if (refereeId !== undefined) {
        dto.jid = refereeId;
      }
      const data = Base64.encode(JSON.stringify(dto));
      const url = `${tulospalveluUrl}/admin/addmessage.php?data=${data}`;

      await waxios.get(url);

      dispatch({
        type: actions.SEND_REFEREE_MESSAGE,
        status: 'SUCCESS',
        data: undefined, // FIXME types
      });
    } catch (error) {
      dispatch({
        type: actions.SEND_REFEREE_MESSAGE,
        status: 'ERROR',
        error,
      });
    }
  };

type AddRefereeGameAction = (refereeId: number, gameId: string, user: User) => void;

export const addRefereeGame: AddRefereeGameAction = (refereeId, gameId, user) =>
  async (dispatch: Dispatch<actions.AddRefereeGame>) => {
    dispatch({
      type: actions.ADD_REFEREE_GAME,
      status: 'PENDING',
    });

    try {
      // https://tulospalvelu.kyykka.fi/admin/addgame.php?jid=1&gid=10
      const dto = {
        jid: refereeId,
        gid: gameId,
        token: user.uuid,
      };
      const data = Base64.encode(JSON.stringify(dto));
      await waxios.get(`${tulospalveluUrl}/admin/addgame.php?data=${data}`);

      dispatch({
        type: actions.ADD_REFEREE_GAME,
        status: 'SUCCESS',
        data: undefined, // FIXME types
      });
    } catch (error) {
      dispatch({
        type: actions.ADD_REFEREE_GAME,
        status: 'ERROR',
        error,
      });
    }
  };
