import React from 'react';

import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { NavLink as RouterNavLink } from 'react-router-dom';
import { Button, Form, NavLink, Row } from 'reactstrap';

import actions from '../../actions';
import ErrorMessage from './ErrorMessage';
import { LoginInput } from './FormInput';

interface StateProps {
  error: Error | undefined;
}

interface DispatchProps {
  login: typeof actions.auth.login;
}

interface Props extends StateProps, DispatchProps { }

export interface OwnState {
  username: string;
  password: string;
}

class Login extends React.Component<Props, OwnState> {
  state: OwnState = {
    username: '',
    password: '',
  };

  login(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    this.props.login(this.state.username, this.state.password);
  }

  handleUserInput(e: FormInputEvent) {
    const name = e.currentTarget.name;
    const value = e.currentTarget.value;

    // Ugly type assertion but works due to component prop checks
    this.setState({ [name]: value } as Pick<OwnState, keyof OwnState>);
  }

  render() {
    const { username, password } = this.state;

    return (
      <div>
        <h2>Kirjaudu</h2>
        <ErrorMessage error={this.props.error}></ErrorMessage>
        <Form onSubmit={(e) => this.login(e)}>
          <LoginInput
            name="username"
            label="Käyttäjätunnus"
            type="text"
            value={username}
            onChange={(e) => this.handleUserInput(e)}
            required
          />
          <LoginInput
            name="password"
            label="Salasana"
            type="password"
            value={password}
            onChange={(e) => this.handleUserInput(e)}
            required
          />
          <Button type="submit">Kirjaudu</Button>
        </Form>
        {/* 2025: salasanan nollaaminen sähköpostin kautta ei enää sallittu */}
        {/* <Row>
          <NavLink tag={RouterNavLink} to="/forgot-password">
            <Button outline>Unohditko salasanan?</Button>
          </NavLink>
        </Row> */}
      </div>
    );
  }
}

const mapStateToProps: MapStateToProps<StateProps, {}, State> = (state: State) => ({
  error: state.auth.error,
});

const mapDispatchToProps: MapDispatchToProps<DispatchProps, {}> = {
  login: actions.auth.login,
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
